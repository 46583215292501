export const Bio = {
  name: "Yazeed Alharbi",
  roles: [
    "Software Engineer",
  ],
  description:
    "I am a software engineer dedicated to building robust products that solve complex challenges. My focus is on creating seamless user experiences by ensuring high-quality solutions. With a strong foundation in software development and growing expertise in cloud computing, I strive to craft solutions that are not only functional but also scalable, intuitive, and efficient for users.",
  github: "https://github.com/yazeed-alharbi",
  resume:
    "https://drive.google.com/file/d/1eMYwDjFYh0PyVJ-rofTvcoYhKpXpaQs7/view?usp=share_link",
  linkedin: "https://www.linkedin.com/in/yazeed-alharbi-10689527b/",
};

export const skills = [
  {
    title: "Programming Lanuagues",
    skills: [
     
      {
        name: "Java",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      },
      {
        name:"C#",
        image: "https://cdn.iconscout.com/icon/free/png-256/free-csharp-logo-icon-download-in-svg-png-gif-file-formats--programming-langugae-language-pack-logos-icons-1175240.png"
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
     
    ],
  },
  {
    title: "Frameworks",
    skills:[
      {
        name: "React Js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      {
        name: "Node Js",
        image: "https://nodejs.org/static/images/logo.svg",
      },
      {
        name: "TailwindCSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Tailwind_CSS_Logo.svg/2560px-Tailwind_CSS_Logo.svg.png",
      },
      
      {
        name: "Flutter",
        image:
          "https://cdn-images-1.medium.com/max/1200/1*5-aoK8IBmXve5whBQM90GA.png",
      },
      
    ],
  },

  {
    title: "Databases",
    skills: [
      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      },
      {
        name: "MongoDB",
        image: "https://cdn.worldvectorlogo.com/logos/mongodb-icon-1.svg"
      }
    ]
      
    
  },
  {
    title: "Tools and Platforms",
    skills:[
      {
        name: "AWS",
        image: "https://download.logo.wine/logo/Amazon_Web_Services/Amazon_Web_Services-Logo.wine.png"
      },
      {
        name: "Firebase",
        image:
          "https://cdn.prod.website-files.com/6047a9e35e5dc54ac86ddd90/63018721094449d9901f5875_cff297d7.png",
      },
      {
        name: "Supabase",
        image: "https://cdn.prod.website-files.com/609bc2fa29b6d5b7f44a2785/647745152a2bc9017927e23a_supabase-logo.webp"
      },
      {
        name:"Git",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/2048px-Git_icon.svg.png"
      },
      {
        name: "Figma",
        image: "https://cdn4.iconfinder.com/data/icons/logos-brands-in-colors/3000/figma-logo-512.png"
      },
      {
        name: "Spline",
        image: "https://june-changelog.s3.eu-central-1.amazonaws.com/spline_icon_twitter_removebg_preview_db2832210b.png"
      },
      {
        name: "Notion",
        image: "https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png"
      },
      
    ]
  }
  
];

export const experiences = [
  {
    id: 0,
    img: "https://i.ibb.co/zhj3bWr/Group-2-3.png",
    role: "Software Developer",
    company: "Tarkeez",
    date: "Jun 2024 - Aug 2024",
    desc: "I led the front-end development of a web application, focusing on improving the UI/UX. I enhanced the user interface by addressing design issues and creating a more intuitive, responsive experience, ensuring the application met user needs and expectations.",
    skills: [
      "ReactJS",
      "TailwindCSS",
      "JavaScript",
    ],
  },

  
];

export const education = [
  {
    id: 0,
    img: "https://i.ibb.co/G2cSL6p/Group-2-2.png",
    school: "King Fahd University of Petroleum & Minerals",
    date: "Aug 2024 - Jun 2025",
    desc: "As part of my concentration program, I am currently taking courses in Cloud and Edge Computing as well as Cloud Applications Engineering. Earlier, I completed a course on Principles of Cloud-based Systems, where I gained deep insights into cloud architecture along with hands-on experience using AWS and Terraform for infrastructure automation. I also took a course in Distributed Systems, where I learned key concepts such as distributed algorithms, synchronization, and fault tolerance, which enhanced my understanding of large-scale distributed computing environments.",
    degree: "Concentration Program (CX), Cloud Computing",
  },
  {
    id: 1,
    img: "https://i.ibb.co/G2cSL6p/Group-2-2.png",
    school: "King Fahd University of Petroleum & Minerals",
    date: "Aug 2020 - Jun 2025",
    grade: "Second Honors Distinction",
    desc: "During my major in Software Engineering, I have taken courses that helped me master various aspects of the field. I studied the full software development life cycle, from requirements gathering and design, where I applied design patterns, to implementation, testing, deployment, and maintenance. In a web development course, I learned both frontend and backend technologies. I also took a database course, where I gained a solid understanding of database management systems. In operating systems course, I learned about multi-process programming, and through a networking course, I developed a deeper understanding of network protocols and communication. These experiences have equipped me with strong technical expertise and problem-solving abilities.",
    degree: "B.S. in Software Engineering",
  },
 
];

export const projects = [
  {
    id: 9,
    title: "Bayyen",
    date: "Aug 2024 - Oct 2024",
    description:
      "Secured 2nd place out of over 220 teams from both industry and academia by designing and developing a prototype that utilized multimodal AI models to automate invoice processing and comparison. I created the user interface using Figma to ensure a clear, user-friendly design and led the implementation of key features, including data extraction and matching processes.",
    image:
      "https://i.ibb.co/zh8FCk1/image.png",
    tags: [
      "Python",
      "UI/UX",
      "Figma",
      "API",

    ],
    category: "web app",
    webapp: "https://www.figma.com/design/5skLXhA5QFM0JZdq3Qidak/Bayyen---Draft-1?node-id=2205-4638&t=Rb51oWrLfNVapvm5-1",
  },
  {
    id: 9,
    title: "KFUPM Clinic",
    date: "Aug 2024 - Dec 2024",
    description:
      "As part of the Distributed Computing course, I contributed to the KFUPM Clinic project, a queue management system that streamlined patient flow. My main responsibilities included designing and implementing the website layout to ensure consistency, and implementing the main queue functionality using ReactJS. This project allowed me to apply my frontend development skills in a distributed systems environment, strengthening my expertise in both UI design and system functionality.",
    image:
      "https://i.ibb.co/WDYZ3sb/image.png",
    tags: [
      "ReactJS",
      "TailwindCSS",
      "Python",
      "UI/UX",
      "Figma",
      "Supabase",
      "Distributed Systems"
      

    ],
    category: "web app",
    webapp: "https://github.com/Yazeed-Alharbi/kfupmclinic",
  },
  {
    id: 9,
    title: "MedAi",
    date: "Jan 2024 - May 2024",
    description:
      "MedAi is a web-based application designed to streamline the process of accessing and managing patient medical records for healthcare professionals. Developed as a project for the SWE 363 course (Web Engineering and Development) at King Fahd University of Petroleum and Minerals (KFUPM), MedAi aims to provide a comprehensive and clear view of a patient's medical history, facilitating quicker and more informed medical decisions by doctors.",
    image:
      "https://i.ibb.co/cY52hPg/image.png",
    tags: [
      "HTML",
      "CSS",
      "JavaScript",
      "Figma",
      "Bootstrap",
      "NunJucks"

    ],
    category: "web app",
    webapp: "https://github.com/Yazeed-Alharbi/MedAi",
  },
];


