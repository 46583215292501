import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import { Snackbar, Alert } from '@mui/material';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  @media (max-width: 960px) {
      padding: 0px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1350px;
  padding: 0px 0px 80px 0px;
  gap: 12px;
  @media (max-width: 960px) {
      flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
      margin-top: 12px;
      font-size: 32px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
      margin-top: 12px;
      font-size: 16px;
  }
`;

const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`;

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`;

const ContactInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme, isError }) => isError ? 'red' : theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const ContactInputMessage = styled.textarea`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme, isError }) => isError ? 'red' : theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const ContactButton = styled.input`
  width: 100%;
  text-decoration: none;
  text-align: center;
  background: #0051FF;
  background: linear-gradient(225deg, #0051FF 0%, #00A1FF 100%);
  padding: 13px 16px;
  margin-top: 2px;
  border-radius: 12px;
  border: none;
  color: ${({ theme }) => theme.text_primary};
  font-size: 18px;
  font-weight: 600;
  cursor: pointer; /* Added to show pointer on hover */
`;

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 2px;
`;

const Contact = () => {
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [errors, setErrors] = useState({});
  const form = useRef();

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const email = form.current.from_email.value.trim();
    const name = form.current.from_name.value.trim();
    const subject = form.current.subject.value.trim();
    const message = form.current.message.value.trim();

    let formErrors = {};

    // Validation checks
    if (!email) {
      formErrors.email = 'Email is required';
    } else if (!isValidEmail(email)) {
      formErrors.email = 'Please enter a valid email';
    }

    if (!name) {
      formErrors.name = 'Name is required';
    }

    if (!subject) {
      formErrors.subject = 'Subject is required';
    }

    if (!message) {
      formErrors.message = 'Message is required';
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setSnackbarMessage('Please fill in the form correctly.');
      setSeverity('error');
      setOpen(true);
      return;
    }

    setErrors({});
    
    // Send the form if validation passes
    emailjs.sendForm('service_d9pwnau', 'template_rpa5t2s', form.current, 'dBDKwvqnYoo8n0oDJ')
      .then((result) => {
        setSnackbarMessage('Email sent successfully!');
        setSeverity('success');
        setOpen(true);
        form.current.reset();
      }, (error) => {
        setSnackbarMessage('Failed to send email. Please try again.');
        setSeverity('error');
        setOpen(true);
      });
  };

  return (
    <Container>
      <Wrapper>
        <Title>Contact</Title>
        <Desc>Feel free to reach out to me for any questions or opportunities!</Desc>
        <ContactForm ref={form} onSubmit={handleSubmit}>
          <ContactTitle>Email Me 🚀</ContactTitle>
          <ContactInput
            placeholder="Your Email"
            name="from_email"
            isError={!!errors.email}
          />
          {errors.email && <ErrorText>{errors.email}</ErrorText>}
          <ContactInput
            placeholder="Your Name"
            name="from_name"
            isError={!!errors.name}
          />
          {errors.name && <ErrorText>{errors.name}</ErrorText>}
          <ContactInput
            placeholder="Subject"
            name="subject"
            isError={!!errors.subject}
          />
          {errors.subject && <ErrorText>{errors.subject}</ErrorText>}
          <ContactInputMessage
            placeholder="Message"
            rows="4"
            name="message"
            isError={!!errors.message}
          />
          {errors.message && <ErrorText>{errors.message}</ErrorText>}
          <ContactButton type="submit" value="Send" />
        </ContactForm>

        <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)} sx={{
    zIndex: 9999,
    position: 'relative',
    marginTop: 5
     // Set a high z-index value to ensure it's above other layers
  }}>
          <Alert onClose={() => setOpen(false)} severity={severity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Wrapper>
    </Container>
  );
};

export default Contact;
